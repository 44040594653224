<template>
  <!-- <b-button
    variant="primary"
    class="shadow-sm"
    @click="retrieveFromMyInfo"
  >
    Retrieve info with <br> MyInfo Business
  </b-button> -->

  <b-img 
    class="cursor-pointer w-100"
    :src="
      require('@/assets/images/singpass/myinfo-biz/Retrieve Myinfo business with Singpass (inline)/Primary.svg')
    "
    @click="retrieveFromMyInfo"
  />
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      default: null,
    }
  },

  methods: {
    retrieveFromMyInfo() {
      const redirectUrl = `${this.$myInfoBizCallbackURL}?company_id=${this.companyId}`

      localStorage.setItem('page_before_myinfo_biz', this.$route.name)

      window.location = redirectUrl
    },

  }
}
</script>

<style>

</style>